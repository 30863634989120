.navbar {
    display:flex;
    flex-direction: row;
    column-gap: 6px;
    padding: 12px 24px;
    margin-top: 24px;
    margin-bottom: 24px;
    width: 95%;
    border-radius: 12px;
    box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.74);
    border-radius: 12px;
    background-color: #E2DAD6;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 400;

}

.link {
    text-decoration: none;
    color: black;
    padding: 12px 24px;
    border-radius: 24px;
    transition: all 0.5s ease;
}

.link:hover {
    transform: scale(110%);
}

.highlight {
    border: 1px solid #6482AD;
    color: #6482AD;
}

.logoNavbar {
    justify-self: start;
    margin-right: 70%;
}

.title {
    color:#6482AD;
}

@media (min-width: 1279px) and (max-width: 1792px) {
    .logoNavbar {
        justify-self: start;
        margin-right: 50%;
    }
}

@media (min-width: 824px) and (max-width: 1292px) {
    .logoNavbar {
        justify-self: start;
        margin-right: 30%;
    }
}

@media (min-width: 596px) and (max-width: 823px) {
    .logoNavbar {
        justify-self: start;
        margin-right: 0%;
    }

    .link {
        font-size: 16px;
    }

}

@media (max-width: 595px) {
    .logoNavbar {
        display: none;
    }

    .navbar {
        flex-direction: column;
        gap: 6px;
        padding: 12px 24px;
        margin-top: 12px;
        width: 95%;
        border-radius: 12px;
    }   
}


