.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    margin-top: 12px;
    color:white;

}

.footer a {
    color: black;
}