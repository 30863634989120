.presentationCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    margin-top: 48px;
    margin-bottom: 48px;
    padding: 12px 24px;
    box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.74);
    border-radius: 12px;
    background-color: #E2DAD6;
    width: 70%;
    height: fit-content;
}

.presentationCard h1 {
    font-size: 24px;
    text-align: center;
    color: #16325B;
    width: 80%;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 400;
}

.presentationCard h3 {
    font-size: 24px;
    color: black;
    width: 80%;
    text-align: center;
}

.presentationCard h3 > span{
    color: #16325B;
}

.imgWrapper {
    border-radius: 50%;
    margin:3px;
    margin-top: 1rem;
    overflow: hidden;
    background-color: white;
    border: 1px solid #16325B;
}

.presentationCard img {
    width: 240px;
    height: 240px;
    min-width: 144px;
    min-height: 144px;
}
/* UNI IMAGE*/
#uniImage {
    min-height: 50px;
    height: 50px;
    min-width: 180px;
    width: 180px;
}


.universityContainer {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    align-items: center;
    margin: 1rem;
}

.universityContainer h3 {
    color: #16325B;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 400;
    width: 100%;
}

/* SKILLS */

.skillsCardContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    padding: 24px 24px;
    flex-wrap: wrap;
    width: 100%;
}

.skillCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-basis: 25%;
    gap: 12px;
    background-color: #E2DAD6;
    border-radius: 12px;
    min-height: 200px;
}

.skillCard h1 {
    width: 100%;
    align-self: center;
    text-align: center;
    color: #6482AD;
    margin: 0;
    font-size: 22px;
    height: fit-content;
    font-family: "Nunito Sans", sans-serif;

}

.skillCard p {
    text-align: center;
    width: 100%;
    font-size: 18px;
    font-weight: normal;
    padding: 0px 24px;
    color: #16325B;
}

.skillCard img {
    width: 140px;
    height: 140px;
    padding: 12px;
    align-self: center;
    justify-self: center;
}

/*titles*/
.title{
    color: white;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 400;
}

.skillsSection {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: fit-content;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.74);
    background-color: #E2DAD6;
    margin: 24px;
    padding: 12px 24px;
}

.skillsSection > h1 {
    padding: 12px 24px;
}

/*DESCARGAR CV*/
.descargarBtn {
    text-decoration: none;
    color: #16325B;
    padding: 12px 24px;
    border-radius: 24px;
    font-size: 18px;
    border: 1px solid #16325B;
    background-color: #E2DAD6;
    transition: all 1s;
    margin: 1rem;
}

.descargarBtn:hover {
    transform: scale(105%);
}

/* LOGO PRESENTATION*/

.logoPresentation {
    padding: 12px 24px;
    color: #6482AD;
    
}

/* FRAMEWORKS*/

.frameCard {
    border:none;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    min-height: 150px;
}

.frameCard h1 {
    background-color: #E2DAD6;
    border:none;
}


.frameCard img {
    width: 140px;
    height: 140px;
}

/* Menu drop down */
.dropbtn {
    display: none;
    margin: 12px;
    border: none;
    background-color: transparent;
    color: white;
    transition: all 0.5s ease;
    font-size: 2rem;
    border-bottom: 1px solid white;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 400;
}

.arrowLogo {
    animation: bounce 1s infinite alternate;

}

.hidden {
    display: none;
}

/* BOUNCE ANIMATION */
@keyframes bounce {
    to { transform: scale(1.2); }
  }

  /* TO TOP OF PAGE BUTTON */

.topOfPageButton {
    padding:12px 24px;
    border-radius: 12px;
    border: 1px solid #6482AD;
    width: 220px;
    margin: 12px;
    color: #16325B;
}

.topOfPageContainer {
    position: sticky;
    top: 90%;
    width: 100%;    
}

  /* MEDIA QUERIES */

@media (min-width: 1279px) and (max-width: 1792px) {
    .presentationCard {
        width: 90%;
    }
}

@media (min-width: 1024px) and (max-width: 1278px) {
    .presentationCard {
        width: 80%;
    }

}

@media (min-width: 824px) and (max-width: 1023px) {
    .presentationCard {
        width: 80%;
    }

}

@media (min-width: 640px) and (max-width: 823px) {
    .presentationCard h3  {
        font-size: 20px;
    }

    .presentationCard h1  {
        font-size: 20px;
    }
    
    .presentationCard img {
        width: 200px;
        height: 200px;
        
    }
}

@media (min-width: 596px) and (max-width: 639px) {
    .presentationCard h3  {
        font-size: 20px;
    }

    .presentationCard h1  {
        font-size: 20px;
    }
    
    .presentationCard img {
        width: 200px;
        height: 200px;
    }


}

@media (max-width: 595px)  {
    .dropbtn {
        display: inline-block;
    }

    .skillCard, .frameCard {
        margin: 0.5rem;
    }

    .skillCard img {
        width: 100px;
        height: 100px;
        
    }

    .frameCard img {
        width: 100px;
        height: 100px;
        
    }


}


@media (max-width: 595px) {
    .frameCard {
        padding: 0;
        margin-bottom: 1rem;
    }
    .skillsCardContainer {
        padding: 0;
        
    }
    .presentationCard   {
        height: fit-content;
    }
    .skillsSection 
    {
        margin: 0;
        margin-bottom: 2rem;
    }
    .presentationCard h3  {
        font-size: 20px;
    }

    .presentationCard h1  {
        font-size: 20px;
    }
    
    .presentationCard img {
        width: 144px;
        height: 144px;
    }

    .skillCard > p {
        padding: 0;
        text-align: center;
        font-size: 1rem;
    }

    .skillCard {
        width: fit-content;
    }
}