.contactContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin:70px;
    margin-top: 70px;
    gap: 12px;
    padding: 12px 32px;
    height: fit-content;
    width: fit-content;
    border-radius: 12px;
    background-color: #E2DAD6;
}

.contactContainer h1 {
    align-self: center;
}

.contactCard {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 12px 24px;
    
}

.contactCard a {
    color: black;
}

.contactCard h2 {
    font-size: 1rem;
    color: #6482AD;
}

.contactCard h4 {
    font-size: 1rem;
}

.imgWrapper {
    border-radius: 50%;
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.74);
    overflow: hidden;
}

.contactCard img {
    padding: 9px;
    width: 78px;
    height: 78px;
}

.infoCard {
    display: flex;
    flex-direction: column;
}

#contactTitle {
    color: white;
}

/* MEDIA QUERIES */

@media (min-width: 1279px) and (max-width: 1792px) {
    .contactCard h2 {
        font-size: 1rem;
    }

    .contactCard h4 {
        font-size: 1rem;
    }

}

@media (min-width: 824px) and (max-width: 1292px) {
    .contactCard h2 {
        font-size: 1.25rem;
    }

    .contactCard h4 {
        font-size: 1.25rem;
    }

}

@media (max-width: 824px) {
    .contactCard h2 {
        font-size: 1rem;
    }

    .contactCard h4 {
        font-size: 0.9rem;
    }

    .contactCard img {
        padding: 9px;
        width: 44px;
        height: 44px;
    }

    .contactCard {
        padding:0px;
    }
    
}

@media (max-width: 596px) {
    .contactContainer {
        margin:24px;
    }

}
@media (min-width: 596px) and (max-width: 823px) {
    .contactContainer {
        width: 95%;
    }
}

@media (max-width: 1292px) {
    .contactContainer {
        width: 95%;
    }
} 

@media (max-width: 595px) {
    .contactContainer {
        width: 95%;
    }
   
}
