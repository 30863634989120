.projectsContainer {
    display: flex;
    flex-direction: column;
    gap: 48px;
    width: 90%;

}

.singleProjectContainer {
    display: flex;
    flex-direction: column;
    gap: 48px;
    margin:12px;
    align-items: center;
    justify-content: center;
}

.singleProjectContainer h1 {
    color: #6482AD;
    font-size: 24px;
    
}

.singleProjectsCards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.singleProjectsCards h1 {
    font-family: "Nunito Sans", sans-serif;

}

.singleProject {
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
    align-items: center;
    padding: 12px 24px;
    border-radius: 12px;
    box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.74);
    background-color: #E2DAD6;
}

.singleProject h1 {
    min-height: 80px;
    font-family: "Nunito Sans", sans-serif;

}

.singleProject h5 {
    text-align: left;
    padding: 12px 24px;
}


.singleProject img {
    width: 244px;
    height: 244x;
    margin: 12px;
}

.tag {
    color: #6482AD;
}

#categoryTitle {
    font-size: 32px;
    color: #E2DAD6;
    border-radius: 12px;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 400;
    align-items: center;
    justify-content: center;
    

}

/* DESCRIPTION POP UP */

.btn {
    border: none;
    background-color: none;

}

.description {
    position: absolute;
    visibility: visible;
    border-radius: 12px;
    background-color: #6482AD;
    color: #E2DAD6;
    width:30%;
}

.hidden {
    position: absolute;
    color: gray;
    box-shadow: none;
    visibility: hidden;
}

.projectLogos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 6px;
    gap: 6px;
    background-color: transparent;
}

.projectLogos button {
    transition: all 0.5s ease;
}

.projectLogos button:hover {
    transform: scale(110%);
}

.projectLogos a {
    transition: all 0.5s ease;
}

.projectLogos a:hover {
    transform: scale(110%);
}

.bold {
    font-weight: bold;
    color: #E2DAD6;
}

/* MEDIA QUERIES */

@media (min-width: 1279px) and (max-width: 1792px) {
    .description {
        
        width:40%;
    }
    
}

@media (min-width: 824px) and (max-width: 1292px) {
    .description {
        
        width:40%;
    }
    
}

@media (min-width: 596px) and (max-width: 823px) {
    .description {
        
        width:50%;
    }
    
}

@media (max-width: 1292px) {
    .description {
        
        width:60%;
    }

    #categoryTitle {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 32px;
    }
} 

@media (max-width: 595px) {

    .description {
        
        width:80%;
    }

    #categoryTitle {
        font-size: 24px;
    }

}

