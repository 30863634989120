.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    top: 35vh;
    position: relative;
}

.loader span {
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 4px;
    animation: chase 2s linear infinite;
}

.loader span:nth-child(2) {
    animation-delay: 0.7s;
}

.loader span:nth-child(3) {
    animation-delay: 1.4s;
}


@keyframes chase {
    0% {top:0;left:0}
    12.5% {top:0;left: 50%}
    25% {top:0;left: 50%}
    37.5% {top:50%; left: 50%}
    50% {top:50%; left: 50%}
    62.5% {top:50%; left: 0}
    75% {top: 50%;left: 0;}
    87.5% {top: 0; left: 0;}
    100% {top: 0; left: 0;}
}

@media (max-width: 900px) {
    .loader {
        top: 20vh;
    }
}

@media (max-width: 700px) {
    .loader {
        top: 10vh;
    }
}